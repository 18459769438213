.sigPad {
  width: 100%;

  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  
}



