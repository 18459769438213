@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Amatic+SC&family=Amita&family=Bad+Script&family=Borel&family=Butterfly+Kids&family=Cookie&family=Damion&family=Dancing+Script&family=Dr+Sugiyama&family=Felipa&family=Grand+Hotel&family=Great+Vibes&family=Herr+Von+Muellerhoff&family=Julee&family=Kaushan+Script&family=Lugrasimo&family=Molle&family=Ms+Madi&family=Pacifico&family=Patrick+Hand&family=Qwitcher+Grypen&family=Sacramento&family=Sofia&family=Tangerine:wght@700&family=Waterfall&family=Yellowtail&display=swap');

/*font-family: 'Alex Brush', cursive;
font-family: 'Amatic SC', cursive;
font-family: 'Amita', cursive;
font-family: 'Bad Script', cursive;
font-family: 'Borel', cursive;
font-family: 'Butterfly Kids', cursive;
font-family: 'Cookie', cursive;
font-family: 'Damion', cursive;
font-family: 'Dancing Script', cursive;
font-family: 'Dr Sugiyama', cursive;
font-family: 'Felipa', cursive;
font-family: 'Grand Hotel', cursive;
font-family: 'Great Vibes', cursive;
font-family: 'Herr Von Muellerhoff', cursive;
font-family: 'Julee', cursive;
font-family: 'Kaushan Script', cursive;
font-family: 'Lugrasimo', cursive;
font-family: 'Molle', cursive;
font-family: 'Ms Madi', cursive;
font-family: 'Pacifico', cursive;
font-family: 'Patrick Hand', cursive;
font-family: 'Qwitcher Grypen', cursive;
font-family: 'Sacramento', cursive;
font-family: 'Sofia', cursive;
font-family: 'Tangerine', cursive;
font-family: 'Waterfall', cursive;
font-family: 'Yellowtail', cursive;*/



.signature-preview {
  margin-top: 20px;
  font-size: 18px;
}

/*Signature Classes*/
.Alex-Brush{font-family: "'Alex Brush', cursive"}
.Amatic-SC{font-family: "'Amatic SC', cursive"}
.Amita{font-family: "'Amita', cursive"}
.Bad-Script{font-family: "'Bad Script', cursive"}
.Borel{font-family: "'Borel', cursive"}
.Butterfly-Kids{font-family: "'Butterfly Kids', cursive"}
.Cookie{font-family: "'Cookie', cursive"}
.Damion{font-family: "'Damion', cursive"}
.Dancing-Script{font-family: "'Dancing Script', cursive"}
.Dr-Sugiyama{font-family: "'Dr Sugiyama', cursive"}
.Felipa{font-family: "'Felipa', cursive"}
.Grand-Hotel{font-family: "'Grand Hotel', cursive"}
.Great-Vibes{font-family: "'Great Vibes', cursive"}
.Herr-Von-Muellerhoff{font-family: "'Herr Von Muellerhoff', cursive"}
.Julee{font-family: "'Julee', cursive"}
.Kaushan-Script{font-family: "'Kaushan Script', cursive"}
.Lugrasimo{font-family: "'Lugrasimo', cursive"}
.Molle{font-family: "'Molle', cursive"}
.Ms-Madi{font-family: "'Ms Madi', cursive"}
.Pacifico{font-family: "'Pacifico', cursive"}
.Patrick-Hand{font-family: "'Patrick Hand', cursive"}
.Qwitcher-Grypen{font-family: "'Qwitcher Grypen', cursive"}
.Sacramento{font-family: "'Sacramento', cursive"}
.Sofia{font-family: "'Sofia', cursive"}
.Tangerine{font-family: "'Tangerine', cursive"}
.Waterfall{font-family: "'Waterfall', cursive"}
.Yellowtail{font-family: "'Yellowtail', cursive"}