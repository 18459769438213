
.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}

.sigPadContainer hr{
  margin-bottom: unset;
  background-color: gainsboro;
}

.sigCanvas{
  width: 100%;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/*#modalSignAndPay.modalContainer {
  position: fixed;
  width: 100vw; 
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

#modalSignAndPay.modal{
  width: 100%;
  max-width: 500px;
  border: 5px;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.modal__bottom {
  text-align: right;
  margin-top: 10px;
}

.signature {
  width: 200px;
  height: 70px;
  margin-top: 20px;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 10px;
  object-fit: contain;
}*/


