#signature_div {
  width: 55%;
  float: right;
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 612px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 577px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 531px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 462px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #signature_div {
    width: 100%;
  }
}

.tab-agreement_content ol,
.tab-agreement_content ul,
.tab-agreement_content li {
  margin-left: 20px !important;
}

.tab-agreement_content>.tab-pane.active>div>ol,
.tab-agreement_content>.tab-pane.active>div>ul {
  list-style: inherit !important;
}
